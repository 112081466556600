body {
  margin: 0;
  background-color: rgb(27, 28, 28);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  outline: none;
  cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
input:-webkit-text-fill-color,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: inherit !important;
  -webkit-box-shadow: 0 0 0px 1000px #1b1c1c inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
  color: #f7f7f7 !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #f7f7f7;
  -webkit-box-shadow: 0 0 0px 1000px rgba(55, 55, 55) inset;
}

input:-moz-autofill {
  -moz-text-fill-color: #f7f7f7;
  -moz-box-shadow: 0 0 0px 1000px rgba(55, 55, 55) inset;
}
input:-o-autofill {
  -o-text-fill-color: #f7f7f7;
  -o-box-shadow: 0 0 0px 1000px rgba(55, 55, 55) inset;
}
input:-khtml-autofill {
  -khtml-text-fill-color: #f7f7f7;
  -khtml-box-shadow: 0 0 0px 1000px rgba(55, 55, 55) inset;
}

button::-moz-focus-inner {
  border: 0;
}

button:focus {
  outline: 0;
}

::-webkit-scrollbar {
  display: none;
}
